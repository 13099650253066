<template>
  <div>
    <ul class="bottom-nav">
      <li class="ripple" v-bind:class="{ active: menuState === 'today' }" @click="PageTo($url_list.TODAY, 'today')">
        <div class="menu-icon today"></div>
        <!-- <img src="@/assets/img/menu/active_today.png" alt="" /> -->
        <div>TODAY</div>
      </li>
      <li
        class="ripple"
        v-bind:class="{ active: menuState === 'careplan' }"
        @click="PageTo($url_list.CAREPLAN, 'careplan')"
      >
        <div class="menu-icon careplan"></div>
        <!-- <img src="@/assets/img/menu/careplan.png" alt="" /> -->
        <div>CARE PLAN</div>
      </li>
      <li class="ripple" v-bind:class="{ active: menuState === 'guide' }" @click="PageTo($url_list.GUIDE, 'guide')">
        <div class="menu-icon guide"></div>
        <!-- <img src="@/assets/img/menu/guide.png" alt="" /> -->
        <div>GUIDE</div>
      </li>
      <li
        class="ripple"
        v-bind:class="{ active: menuState === 'manage' }"
        @click="PageTo($url_list.MANAGEMENT, 'manage')"
      >
        <div class="menu-icon manage"></div>
        <!-- <img src="@/assets/img/menu/manage.png" alt="" /> -->
        <div>MANAGE</div>
      </li>
      <li
        class="ripple"
        v-bind:class="{ active: menuState === 'carenote' }"
        @click="PageTo($url_list.CARENOTE, 'carenote')"
      >
        <div class="menu-icon carenote"></div>
        <!-- <img src="@/assets/img/menu/carenote.png" alt="" /> -->
        <div>CARE NOTE</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { eventBus } from '@/main';

export default {
  data() {
    return {
      selectedUrl: '',
      menuState: 'today',
    };
  },
  beforeDestroy() {
    eventBus.$off('gotoGuideClickFromTodayBanner');
  },
  mounted() {
    eventBus.$on('gotoGuideClickFromTodayBanner', () => {
      this.menuState = 'guide';
    });
    const url = this.$route.fullPath;
    this.menuState = url.split('/').join('');
  },
  methods: {
    PageTo(url, menu) {
      console.log(this.selectedUrl+','+  url);
      if (this.selectedUrl === url) {
        console.log('같은페이지 인경우 리플래시 처리');
        return;
      }
      this.selectedUrl = url;
      this.$router.push(url);
      this.menuState = menu;
    },
  },
};
</script>

<style lang="scss">
// .bottom-nav {
//   position: fixed;
//   bottom: 0;
//   width: 100%;
//   padding: 10px;
//   box-shadow: 0px 0px 6px 0px #ececec;
//   background: #fff;
//   margin: 0;
//   li {
//     float: left;
//     padding: 5px;
//     width: 20%;
//     text-align: center;
//     word-break: keep-all;
//     font-size: 0.7rem;
//     list-style: none;
//     letter-spacing: -0.5px;
//     // .anticon {
//     //   margin: 0;
//     //   font-size: 20px;
//     // }
//     .menu-icon {
//       display: inline-block;
//       width: 32px;
//       height: 32px;
//       &.today {
//         background: url('~@/assets/img/menu/today.png');
//         background-size: cover;
//       }
//       &.careplan {
//         background: url('~@/assets/img/menu/careplan.png');
//         background-size: cover;
//       }
//       &.guide {
//         background: url('~@/assets/img/menu/guide.png');
//         background-size: cover;
//       }
//       &.manage {
//         background: url('~@/assets/img/menu/manage.png');
//         background-size: cover;
//       }
//       &.carenote {
//         background: url('~@/assets/img/menu/carenote.png');
//         background-size: cover;
//       }
//     }
//     &.active {
//       .menu-icon {
//         &.today {
//           background: url('~@/assets/img/menu/active_today.png');
//           background-size: cover;
//         }
//         &.careplan {
//           background: url('~@/assets/img/menu/active_careplan.png');
//           background-size: cover;
//         }
//         &.guide {
//           background: url('~@/assets/img/menu/active_guide.png');
//           background-size: cover;
//         }
//         &.manage {
//           background: url('~@/assets/img/menu/active_manage.png');
//           background-size: cover;
//         }
//         &.carenote {
//           background: url('~@/assets/img/menu/active_carenote.png');
//           background-size: cover;
//         }
//       }
//     }
//   }
// }
</style>