<template>
  <a-layout id="layout">
    <AppSider></AppSider>
    <a-layout>
      <!-- <AppTopbar></AppTopbar> -->
      <a-layout-content class="app-contents">
        <!-- slide fade 전환 애니메이션 추가 -->
        <transition name="slide-fade" mode="out-in"> <router-view></router-view> </transition>
        <!-- <router-view></router-view> -->
      </a-layout-content>

      <!-- footer 넣기 -->
      <AppBottomNav></AppBottomNav>
    </a-layout>
  </a-layout>
</template>

<script>
import AppSider from '@/components/common/Sider.vue';
import AppTopbar from '@/components/common/Topbar.vue';
import AppBottomNav from '@/components/common/BottomNav.vue';

export default {
  components: { AppSider, AppTopbar, AppBottomNav },
};
</script>
<style lang="scss">
.ant-layout {
  height: 100%;
}
.ant-layout-content {
  height: 100%;
}
// .modal {
//   width: 250px;
//   height: 200px;
//   padding: 10px;
//   margin: 0 auto;
//   margin-top: 20px;
//   border-radius: 2%;
//   background-color: #e0e0e0;
// }
// .fade-enter {
//   opacity: 0;
// }

// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.3s ease-out;
// }

// .fade-leave-to {
//   opacity: 0;
// }

// .slide-fade-enter {
//   transform: translateX(10px);
//   opacity: 0;
// }

// .slide-fade-enter-active,
// .slide-fade-leave-active {
//   transition: all 0.2s ease;
// }

// .slide-fade-leave-to {
//   transform: translateX(-10px);
//   opacity: 0;
// }
</style>
