<template>
  <a-layout-sider v-model="collapsed">
    <!-- <div class="logo">
      <img src="@/assets/img/hive_sider_logo.png" alt="" />
    </div> -->
    <div class="menu-wrap">
      <a-menu class="menu" mode="inline" :default-selected-keys="['1']" :default-open-keys="['sub1']">
        <!-- 메뉴1 -->
        <a-sub-menu key="sub1">
          <span slot="title">
            <a-icon type="line-chart" />
            <span>{{ $t('sider-top-dashBoard') }}</span>
          </span>
          <a-menu-item key="1"> {{ $t('sider-dashBoard.menu1') }} </a-menu-item>
          <a-menu-item key="2"> {{ $t('sider-dashBoard.menu2') }} </a-menu-item>
          <a-menu-item key="3"> {{ $t('sider-dashBoard.menu3') }} </a-menu-item>
        </a-sub-menu>

        <!-- 메뉴2 -->
        <a-sub-menu key="sub2">
          <span slot="title">
            <a-icon type="exclamation-circle" />
            <span>{{ $t('sider-top-event') }}</span>
          </span>
          <!-- <a-sub-menu key="sub2-1">
            <span slot="title">
              <span>{{ $t('sider-event.menu1') }}</span>
            </span>
            <a-menu-item key="4">{{ $t('sider-event.menu1-sub.sub1') }}</a-menu-item>
            <a-menu-item key="5">{{ $t('sider-event.menu1-sub.sub2') }}</a-menu-item>
          </a-sub-menu> -->
          <a-menu-item key="4">
            <router-link to="/eventList">{{ $t('sider-event.menu1-sub.sub1') }}</router-link>
          </a-menu-item>

          <a-menu-item key="6">{{ $t('sider-event.menu2') }}</a-menu-item>
        </a-sub-menu>

        <!-- 메뉴3 -->
        <a-sub-menu key="sub3">
          <span slot="title">
            <a-icon type="laptop" />
            <span>{{ $t('sider-top-dist') }}</span>
          </span>
          <a-sub-menu key="sub3-1">
            <span slot="title">
              <span>{{ $t('sider-dist.menu1') }}</span>
            </span>
            <a-menu-item key="7">{{ $t('sider-dist.menu1-sub.sub1') }}</a-menu-item>
            <a-menu-item key="8">{{ $t('sider-dist.menu1-sub.sub2') }}</a-menu-item>
          </a-sub-menu>
          <a-menu-item key="9">{{ $t('sider-dist.menu2') }}</a-menu-item>
          <a-menu-item key="10">{{ $t('sider-dist.menu3') }}</a-menu-item>
        </a-sub-menu>

        <!-- 메뉴4 -->
        <a-menu-item key="11">
          <a-icon type="phone" />
          <span>{{ $t('sider-top-AS') }}</span>
        </a-menu-item>

        <!-- 메뉴5 -->
        <a-sub-menu key="sub4">
          <span slot="title">
            <a-icon type="cluster" />
            <span>{{ $t('sider-top-system') }}</span>
          </span>
          <a-menu-item key="12">
            <router-link to="/systemUser">{{ $t('sider-system.menu1') }}</router-link>
          </a-menu-item>
          <a-menu-item key="13">
            <router-link to="/systemApprove">{{ $t('sider-system.menu2') }}</router-link>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>

      <!-- <div class="dpVersion">version : {{ this.appVersion }}</div> -->
    </div>
  </a-layout-sider>
</template>
<script>
import { eventBus } from '@/main';
export default {
  data() {
    return {
      collapsed: true,
      appVersion: this.$store.getters.distInfo.appVersion,
    };
  },
  created() {
    eventBus.$on('ToggleCollapsed', () => {
      this.collapsed = !this.collapsed;
      //   console.log(this.collapsed);
    });
  },
};
</script>
<style lang="scss">
.dpVersion {
  color: gray;
  font-size: 12pt;
  position: fixed;
  bottom: 0;
  padding: 20px;
}
.ant-menu-submenu-popup {
  display: none;
}
.ant-layout-sider {
  position: relative;
  background: $primary-color !important;
  flex: 0 0 280px !important;
  max-width: 280px !important;
  min-width: 280px !important;
  width: 280px !important;

  &.ant-layout-sider-collapsed {
    flex: 0 0 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
    width: 0 !important;

    .ant-menu-inline-collapsed {
      > .ant-menu-item {
        padding: 0 22px !important;
      }

      > .ant-menu-submenu > .ant-menu-submenu-title {
        padding: 0 22px !important;
      }
    }
  }

  .logo {
    border-bottom: 1px solid #1b1b1b;
    padding: 20px;

    img {
      height: 18px;
      display: block;
      transition: 0.5s;

      &.symbol {
        margin: auto;
      }
    }
  }

  .menu-wrap {
    height: calc(100% - 60px);
    overflow-y: overlay;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 0;
    }
  }

  .ant-menu-submenu {
    > .ant-menu {
      background-color: #1b1b1b;
      // padding: 20px 0;
    }

    .ant-menu-item {
      height: 40px !important;
      line-height: 40px !important;
    }
  }

  .ant-menu {
    background-color: $primary-color;
    color: #fff;
    border-right: none;

    .ant-menu-item-selected > a,
    .ant-menu-item-selected > .ant-menu-item > a:hover {
      color: $point-color;
    }

    &:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        // background-color: $primary-color;
        color: $point-color;
        background-color: #1b1b1b;

        a {
          color: $point-color;

          &:hover {
            color: $point-color;
          }
        }
      }
    }

    .ant-menu-submenu-selected {
      color: $point-color;
    }

    .ant-menu-submenu-title {
      cursor: pointer;
      height: 50px !important;
      line-height: 50px !important;

      &:hover {
        color: $point-color;

        .ant-menu-submenu-arrow::before {
          background: $point-color;
        }

        .ant-menu-submenu-arrow::after {
          background: $point-color;
        }
      }
    }

    .ant-menu-item {
      cursor: pointer;
      margin: 0;
      width: 100%;

      &:hover {
        color: $point-color;
      }

      &::after {
        border-color: $point-color;
        border-left: 3px solid $point-color;
        border-right: 0;
        left: 0;
        right: initial;
      }

      a {
        color: #fff;

        &:hover {
          color: $point-color;
        }
      }
    }
  }
}
</style>