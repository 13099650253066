<template>
  <a-layout-header class="top-menu">
    <a-icon class="trigger" type="menu" @click="toggleCollapsed" />
    <div class="top-right">
      <span class="current-time">기준 : (GMT+9) 목요일, 2021년 5월 27일, 오후 2:34</span>
      <a-icon type="setting" theme="filled" />
      <a-divider type="vertical" />
      <a-dropdown :trigger="['click']">
        <span class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          <a-avatar size="small" icon="user" />
          <span v-if="isUserLogin">{{ $store.state.username }}</span>
          <a-icon type="down" />
        </span>
        <a-menu slot="overlay">
          <a-menu-item key="0">
            <a>1st menu item</a>
          </a-menu-item>
          <a-menu-item key="1">
            <a>2nd menu item</a>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="3"> 3rd menu item </a-menu-item>
        </a-menu>
      </a-dropdown>
      <a-divider type="vertical" />
      <a-badge dot>
        <a-icon type="bell" />
      </a-badge>
      <a-divider type="vertical" />
      <a-select :value="$i18n.locale" class="small" @change="handleChange">
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option value="ko" @click="toggleLanguage('ko')"> Korean </a-select-option>
        <a-select-option value="en" @click="toggleLanguage('en')"> English </a-select-option>
      </a-select>
      <a-divider type="vertical" />
      <a href="javascript:;" @click="logoutUser" class="logout-button"> <a-icon type="logout" /> </a>
    </div>
  </a-layout-header>
</template>
<script>
import { eventBus } from '@/main';
import { deleteCookie } from '@/utils/cookies';
import { logOutUser } from '@/api/auth';

export default {
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    isUserLogin() {
      return this.$store.getters.isLogin;
    },
    logoLink() {
      return this.$store.getters.isLogin ? '/main' : '/login';
    },
  },
  methods: {
    menuClick() {
      this.$emit('menuClick', 'test');
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      eventBus.$emit('ToggleCollapsed');
    },
    toggleLanguage(lang) {
      this.$i18n.locale = lang;
      // this.$cookies.set('locale', lang, '4y');
      console.log(document.cookie);
    },
    async logoutUser() {
      if (confirm('Are you sure you want to log out?')) {
        // store action에서 처리
        await this.$store.dispatch('LOGOUT', { userid: this.$store.state.userid });
        this.$router.push('/login');
      }
    },
  },
};
</script>
<style lang="scss">
.top-menu {
  padding: 15px;
}

.trigger {
  font-size: 18px;
  line-height: 50px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: $point-color;
  }
}
.top-menu.ant-layout-header {
  background: #fff;
  padding: 0;
  font-size: 12px;
  height: 50px;
  line-height: 50px;
  box-shadow: 0 0 10px 0 #e4e4e4;
  z-index: 100;
}
.top-right {
  float: right;
  padding: 0 15px;
  .current-time {
    margin-right: 10px;
  }
  .ant-avatar {
    margin-right: 10px;
  }
  .anticon {
    font-size: 14px;
    &.anticon-bell {
      font-size: 18px;
      vertical-align: middle;
    }
  }
  .ant-select {
    .ant-select-selection {
      border: none;
      border-radius: 0;
      box-shadow: none;
      &:active,
      &:focus {
        box-shadow: none;
      }
      .ant-select-arrow {
        color: $primary-color;
      }
    }
  }
}
</style>